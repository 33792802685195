export const initialState = {
  // Status
  sendingStatus: false,

  // Blogs
  blogs: [],
  // Blog details
  blog: {},

  // Employees
  employees: [],
  
  // URLS
  baseUrl: "https://lovasoa.mg/back/public/api/v1",
  imageUrl: "https://lovasoa.mg/back/storage/app/public",
}

export const reducerService = (state, action) => {
  switch (action.type) {
    case "GET_BLOGS":
      return {
        ...state,
        blogs: action.payload.blogs
      }
    case "GET_BLOG_DETAILS":
      return {
        ...state,
        blog: action.payload.blog
      }
    
    case "GET_EMPLOYEES":
        return {
          ...state,
          employees: action.payload.employees,
        };
    
    case "STATUS_OK":
      return {
        ...state,
        sendingStatus: true,
      }

    case "STATUS_BAD":
      return {
        ...state,
        sendingStatus: false,
      }
  
    default:
      return {...state};
  }
}
