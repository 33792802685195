import React from "react";
import ChapelImage from "../../../../assets/images/services/lovasoa_chapel.jpeg";
import FlmImage from "../../../../assets/images/services/lovasoa_flm.jpeg";
import Image from "../../../../assets/images/services/Team-lovasoa-2.jpeg";
import coffeeBreakImage from "../../../../assets/images/services/coffee_break.jpeg";

export default function Conference() {
  return (
    <React.Fragment>
      <section id="about-service" className="about-service">
        <div className="container">
          <div className="row gx-0">
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <div className="content">
                <h1
                  className="fw-bold"
                  style={{
                    color: "#433f39",
                  }}
                >
                  Welcome to Our Charming Guesthouse :
                </h1>
                <p
                  style={{
                    fontSize: "24px",
                    lineHeight: "2rem",
                    color: "#433f39",
                  }}
                >
                  Your Haven for Conferences and Comfort!
                </p>
                <p>
                  Nestled amidst the serene landscapes of Lovasoa, our
                  enchanting Guesthouse beckons with unparalleled hospitality
                  and modern amenities. Whether you're hosting a conference,
                  seeking comfortable accommodation, or simply indulging in
                  delectable cuisine, we have everything you need for a
                  memorable stay.
                </p>
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center">
              <img src={Image} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>

      {/* CONFERENCES & MEETING ROOMS */}
      <section className="cultural activities-details">
        <div className="container">
          <div className="activities-details-title mb-5" data-aos="zoom-out">
            <p>CONFERENCES & MEETING ROOMS</p>
            <div>
              <span>
                Elevate your business gatherings and events with our
                well-equipped conference facilities and meeting rooms. We
                specialize in bringing your meeting and event ideas to life. Our
                3 versatile meeting rooms are spacious and allow you to organize
                forums, summits, regional conferences and exchanges, exhibitions
                and major professional events to host all kinds of events. The
                first meeting room offers a total of 200 m2 to accommodate up to
                100 participants (120 for seminars). The second meeting room
                offering a total of 100 m2 to accommodate up to 30 participants
                (50 for seminars). The third small meeting rooms offering a
                total of 50 m2 to accommodate up 15 to participants.
              </span>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12 position-relative">
                <div className="row mb-4">
                  <div className="col-md-6 col-lg-5 mb-3">
                    <img
                      src={ChapelImage}
                      alt=""
                      className="img-fluid rounded-4"
                    />
                  </div>
                  <div className="col-lg-1 d-none d-lg-block position-relative">
                    <div className="title-vertical d-flex justify-content-start"></div>
                  </div>
                  <div className="col-md-6 col-lg-5 section-md-t3 pb-5">
                    <div className="title-box-d">
                      <p className="title-d">
                        <span className="color-d">Our service promise</span>
                      </p>
                    </div>
                    <p className="color-text-a">
                      Our Meeting Package brings together everything you could
                      need: Rooms with audio-visual equipment, coffee breaks,
                      and free fiber optic connection.
                    </p>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-6 col-lg-5 mb-3">
                    <img
                      src={coffeeBreakImage}
                      alt="coffee break"
                      className="img-fluid rounded-4"
                    />
                  </div>
                  <div className="col-lg-1 d-none d-lg-block position-relative">
                    <div className="title-vertical d-flex justify-content-start"></div>
                  </div>
                  <div className="col-md-6 col-lg-5 section-md-t3 pb-5">
                    <div className="title-box-d">
                      <p className="title-d">
                        <span className="color-d">Coffee break</span>
                      </p>
                    </div>
                    <p className="color-text-a">
                      With a selection of distinctive menus, surprise your
                      guests with flavors that will please any palate. A wide
                      range of dining options is available to match the scale
                      and theme of the event, reflecting an authentic experience
                      as well as customized thematic choices for delegates.
                    </p>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-6 col-lg-5 mb-3">
                    <img
                      src={FlmImage}
                      alt=""
                      className="img-fluid rounded-4"
                    />
                  </div>
                  <div className="col-lg-1 d-none d-lg-block position-relative">
                    <div className="title-vertical d-flex justify-content-start"></div>
                  </div>
                  <div className="col-md-6 col-lg-5 section-md-t3 pb-5">
                    <div className="title-box-d">
                      <p className="title-d">
                        <span className="color-d">The chapel</span>
                      </p>
                    </div>
                    <p className="color-text-a">
                      Our first value lies in Christendom, we have a chapel
                      right at the entrance of our establishment to assert this
                      value at the first sight of our customers. A worship is
                      organized every second and fourth of Sunday at 7 pm where
                      our guests will be invited. Otherwise, the chapel can be
                      transformed into a special meeting room for other
                      evangelical events with a surcharge.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Fin CONFERENCES & MEETING ROOMS */}
    </React.Fragment>
  );
}
