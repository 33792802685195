import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import ContextService from "../../../../context/ContextService";
import { format } from "date-fns";
import ReactQuill from "react-quill";
import { Link } from "react-router-dom";
import imageBlogDefault from "../../../../assets/images/Blog/default_1.png";

export default function BlogDetails() {
  const { getBlogDetails, state, resetWindowY } = useContext(ContextService);
  const { id } = useParams();

  useEffect(() => {
    getBlogDetails(id);
  }, [state.blog, getBlogDetails, id]);

  return (
    <React.Fragment>
      <div className="container mt-5 py-5">
        <div className="row">
          <div className="col-lg-8">
            <section id="blog-details" className="blog-details section">
              <div className="container">
                <article className="article">
                  <div className="post-img">
                    {!!state.blog?.image && (
                      <img
                        src={`${state?.imageUrl}/${state.blog?.image}`}
                        alt=""
                        className="img-fluid"
                      />
                    )}
                  </div>

                  <h2 className="title">{state.blog?.title}</h2>

                  <div className="meta-top">
                    <ul>
                      <li className="d-flex align-items-center">
                        {!!state.blog?.tag && <i className="bi bi-balloon"></i>}{" "}
                        <a href="blog-details.html">{state.blog?.tag}</a>
                      </li>
                    </ul>
                  </div>

                  <div className="content">
                    <ReactQuill
                      value={state.blog?.description}
                      readOnly={true}
                      theme={"bubble"}
                    />
                  </div>
                </article>
              </div>
            </section>
          </div>

          <div className="col-lg-4 sidebar">
            <div className="widgets-container">
              <div className="recent-posts-widget widget-item">
                <h3 className="widget-title">Recent Posts</h3>

                {state.blogs?.slice(0, 6).map((blog, index) => (
                  <React.Fragment>
                    {blog?.id != id && (
                      <div key={index} className="post-item">
                        <img
                          src={
                            blog?.image
                              ? `${state.imageUrl}/${blog?.image}`
                              : imageBlogDefault
                          }
                          alt=""
                          className="flex-shrink-0"
                        />
                        <div>
                          <h4>
                            <Link
                              onClick={resetWindowY}
                              to={`/blogs/${blog?.id}`}
                            >
                              {blog?.tag ? blog?.tag : blog?.title.slice(0, 24)}
                            </Link>
                          </h4>
                          <time>
                            {format(new Date(blog?.updated_at), "yyyy-MM-dd")}
                          </time>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
