import React, { useContext } from "react";
import "../../../../assets/css/Blog.css";
import imageBlogDefault from "../../../../assets/images/Blog/default_1.png";
import ContextService from "../../../../context/ContextService";
import "react-quill/dist/quill.snow.css";
import { format } from "date-fns";
import { Link } from "react-router-dom";

export default function Blog() {
  // CONTEXT SERVICE
  const { state, resetWindowY } = useContext(ContextService);

  const stripHtmlTags = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
  };

  return (
    <React.Fragment>
      <section id="blog-posts" className="blog-posts section">
        <div className="container mt-5 py-5">
          <div className="row gy-4">
            {state.blogs?.map((blog, index) => (
              <div key={index} className="col-lg-4 col-md-6">
                <article className="position-relative h-100">
                  <div className="post-img position-relative overflow-hidden">
                    <img
                      src={
                        blog?.image
                          ? `${state?.imageUrl}/${blog?.image}`
                          : imageBlogDefault
                      }
                      className={blog?.image ? `img-fluid` : "img-fluid w-100"}
                      alt=""
                    />
                    <span className="post-date">
                      {format(new Date(blog?.updated_at), "yyyy-MM-dd")}
                    </span>
                  </div>

                  <div className="post-content d-flex flex-column">
                    <h3 className="post-title">{blog?.title}</h3>

                    <div className="meta d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        {!!blog?.tag && <i className="bi bi-balloon"></i>}{" "}
                        <span className="ps-2">{blog?.tag}</span>
                      </div>
                    </div>

                    <p>
                      {stripHtmlTags(`${blog.description.slice(0, 225)}...`)}
                    </p>

                    <hr />

                    <Link
                      onClick={resetWindowY}
                      to={`/blogs/${blog?.id}`}
                      className="readmore stretched-link"
                    >
                      <span>Read More</span>
                      <i className="bi bi-arrow-right"></i>
                    </Link>
                  </div>
                </article>
              </div>
            ))}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
