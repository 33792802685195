import React, { useContext } from "react";
import "../../../../assets/css/teamEmployes.css";
import Image from "../../../../assets/images/services/Team-lovasoa-3.jpeg";
//import { NavLink } from "react-router-dom";

import Employe2 from "../../../../assets/images/chefs/default-avatar.png";
import ContextService from "../../../../context/ContextService";

export default function Founding() {
  // IMPLEMENT USE CONTEXT
  const { state } = useContext(ContextService);

  return (
    <React.Fragment>
      <section className="activities-archives"></section>

      <section className="section-about">
        <div className="container">
          <div className="row">
            <div className="col-md-12 section-t8 position-relative">
              <div className="row">
                <div className="col-md-6 col-lg-5">
                  <img src={Image} alt="" className="img-fluid rounded-4" />
                </div>
                <div className="col-lg-2  d-none d-lg-block position-relative">
                  <div className="title-vertical d-flex justify-content-start"></div>
                </div>
                <div className="col-md-6 col-lg-5 section-md-t3 pb-5">
                  <div className="title-box-d">
                    <p className="title-d">
                      <span className="color-d">Our employees</span>
                    </p>
                  </div>
                  <p className="color-text-a">
                    We uphold the vision, values and missions by their
                    commitment and diligence. The permanent workers (Malagasy
                    and Norwegians) are about 60 and are supported by skilled
                    temporary staffs and volunteers during peak seasons.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <section id="team" className="team">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Organigramme</h2>
              </div>

              <div className="row">
                {state.employees?.map((employee, index) => (
                  <div
                    key={index}
                    className="col-lg-6 mt-4 mb-3 mt-lg-0"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  >
                    <div className="member d-flex align-items-center">
                      <div className="pic">
                        <img
                          src={
                            employee?.image
                              ? `${state.imageUrl}/${employee?.image}`
                              : Employe2
                          }
                          className="img-fluid"
                          alt={employee?.occupation}
                        />
                      </div>
                      <div className="member-info">
                        <h4>{employee?.full_name}</h4>
                        <span>{employee?.occupation}</span>
                        <p>{employee?.bio}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </section>
    </React.Fragment>
  );
}
